<template>
    <div class="password-container">
      <input
        :type="isPasswordVisible ? 'text' : 'password'"
        v-model="localValue"
        :placeholder="placeholder ? placeholder : $tc('password')"
        class="form-control"
      />
      <i :class="['eye-icon', isPasswordVisible ? 'icon-eye2' : 'icon-eye-blocked']" @click="togglePasswordVisibility">
      </i>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        value: String,
        placeholder: String
    },  
    data() {
      return {
        isPasswordVisible: false
      };
    },
    computed: {
        localValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    methods: {
      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
      }
    }
  };
  </script>
  
  <style scoped>
  .password-container {
    position: relative;
    width: fit-content;
  }
  
  input[type="password"],
  input[type="text"] {
    height: auto;
  }
  
  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  </style>
  