<template>
  <div>
    <PoliciesAndTerms />
    <section id="home" class="video-hero" data-section="home">
      <div class="overlay top-bk"></div>
    </section>
    <div class="colorlib-work-featured colorlib-bg-white">
      <div class="container">
        <div v-if="!isSubmitted" class="row">
          <div class="col-md-12 col-md-offset-2 text-center colorlib-heading animate-box">
            <h1>
              {{ $t("site.register.title") }}
              <i class="icon-profile-male"></i>
            </h1>
            <p>{{ $t("site.register.subtitle") }}</p>
          </div>
        </div>
        <div v-if="!isSubmitted" class="row d-flex justify-content-center">
          <div class="col-md-8 col-md-pull-4 animate-box">
            <form v-on:submit.prevent="sendNewUser" class="form-signin text-left">
              <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" />
              <div class="card-plain_">
                <div class="row mt-4">
                  <div class="col-md-6 form-group">
                    <label for="name">{{ $tc("name", 1) }}:</label>
                    <input v-model="newUser.name" :placeholder="$tc('name', 1)" name="name" class="form-control"
                      id="name" type="text" />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="email">{{ $tc("email") }}:</label>
                    <input v-model="newUser.email" name="email" class="form-control" id="email" type="email"
                      :placeholder="$tc('email')" />
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="password">{{ $tc("password") }}:</label>
                    <InputPassword v-model="newUser.password" :placeholder="$tc('password')" name="password"
                      id="password" type="password" autocomplete="true" />
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="reapeat-password">
                      {{ $tc("password_repeat") }}:
                    </label>
                    <InputPassword v-model="newUser.password_confirmation" :placeholder="$tc('password_repeat')"
                      name="reapeat-password" id="reapeat-password" autocomplete="true" type="password" />
                  </div>
                  <div class="row_">
                  </div>
                  <div class="col-md-12 form-group mt-3">
                    <CheckBox v-model="newUser.accept_terms_use" label />
                    <a @click.prevent="setShowTermsUse(true)" href="#">{{
                      $t("site.register.registered.accept_terms")
                    }}</a>
                  </div>
                  <div class="col-md-12 form-group">
                    <CheckBox v-model="newUser.accept_privacy_policy" label />
                    <a @click.prevent="setShowPrivacyPolicy(true)" href="#">{{
                      $t("site.register.registered.accept_privacy_policy")
                    }}</a>
                  </div>

                  <div class="col-md-12">
                    <ErrorsArea :errors-data="formErrors" />
                  </div>
                  <div class="col-md-12">
                    <hr />
                    <button type="sumbmit" class="btn btn-success mt-2 col-md-4">
                      <i class="nc-icon nc-check-2"></i>
                      {{ $t("action.register") }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div v-else>
          <h1 class="text-center">
            {{ this.$t("site.register.registered.title") }}
          </h1>
          <p class="text-center" v-html="this.$t('site.register.registered.msn', { email: this.emailSent })
            "></p>
          <p class="text-center">
            {{ this.$t("site.register.registered.check_email") }}
          </p>
          <div class="text-center check-icon text-center text-success">
            <i class="icon-check"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import ErrorsArea from "@/components/ErrorsArea/";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PoliciesAndTerms from "@/components/PoliciesAndTerms/";
import "element-ui/lib/theme-chalk/index.css";
import InputPassword from "../../Inputs/InputPassword.vue";
import { getToken, loadRecaptcha } from '@/helpers/getToken';

export default {
  name: "ContactPage",
  components: {
    ErrorsArea,
    Loading,
    PoliciesAndTerms,
    InputPassword,
  },
  data() {
    return {
      recaptchaToken: null,
      formErrors: {},
      isLoading: false,
      showTermsUse: false,
      emailSent: "",
      accountCreated: false,
      isSubmitted: false,
      langs: [
        { id: 3, code: "en", label: "EN", img: "en.svg" },
        { id: 1, code: "pt", label: "PT", img: "pt.svg" },
        { id: 2, code: "pt_br", label: "PT-BR", img: "pt-br.svg" },
      ],
      newUser: {
        name: '',
        company_name: '',
        country_id: null,
        language_id: 1,
        plan_currency_id: 1,
        plan_id: null,
        email: '',
        password: '',
        password_confirmation: '',
        operating_area_id: 5,
        is_external: false,
        profile_id: null,
        account_id: null,
        accept_terms_use: false,
        accept_privacy_policy: false,
        token: '',
        permissions: {},
        referral_status: false,
        referral: ''
      },
    };
  },
  computed: {
    ...mapState("utils", [
      "plans",
      "currencies",
      "selectedPlanId",
      "selectedCurrencyId",
      "countries",
    ]),
    ...mapGetters("utils", ["selectedPlan"]),
    languages() {
      return this.$store.state.utils.languages;
    },
    selectedLang() {
      return this.languages.find((lang) => {
        return lang.id === this.selectedLanguageId;
      });
    },
    translateCode() {
      return this.i18n.locale
    },
    selectedLanguageId: {
      get() {
        return this.$store.state.utils.selectedLanguageId;
      },
      set(newValue) {
        this.setSelectedLanguageId(newValue);

        this.$i18n.locale = this.selectedLang.code;
        this.selectedLangCode = this.selectedLang.code;
        localStorage.LangCode = this.selectedLang.code;
      },
    },
    currencyId: {
      get() {
        return this.selectedCurrencyId;
      },
      set(newValue) {
        this.setSelectedCurrencies(newValue);
      },
    },
  },
  async mounted() {
    // this.$helpers.ga.openPage({
    //   page: "create_account",
    //   title: "Create Account",
    // });
    this.$helpers.scrollTop(0);
    this.accountCreated = false;

    try {
      const grecaptcha = await loadRecaptcha()
      await grecaptcha.enterprise.ready()
    } catch (error) {
      console.error("Erro ao carregar reCAPTCHA:", error)
    }
  },
  beforeDestroy() {
    this.accountCreated = false;
  },
  methods: {
    ...mapMutations("utils", [
      "setShowTermsUse",
      "setShowPrivacyPolicy",
      "setSelectedCurrencies",
      "setSelectedLanguageId",
    ]),
    ...mapActions("User", ["loginUser"]),
    async sendNewUser() {
      // this.$helpers.ga.event({
      //   event: "submit_form",
      //   label: "click_btn_register",
      //   value: this.selectedPlanId,
      // });

      // gtag('event', 'conversion', {
      // 'send_to': 'AW-11158645789/czcaCPqW3dIZEJ3Y7cgp',
      // 'event_callback': callback
      // });

      // const token = await getToken('NEW_USER');

      try {
        this.recaptchaToken = await getToken('NEW_USER')
      } catch (error) {
        console.error("Erro ao carregar reCAPTCHA:", error)
      }

      this.formErrors = {};
      this.isLoading = true;
      this.newUser.country_id = 1;
      this.newUser.company_name = this.$tc("company_name", 1);

      this.newUser.plan_currency_id = 2; // Dolar

      if (this.$i18n.locale === "pt") {
        this.newUser.plan_currency_id = 1;
      } else if (this.$i18n.locale === "pt_br") {
        this.newUser.plan_currency_id = 4;
      }

      this.newUser.language_id = 3; // English

      if (this.$i18n.locale === "pt") {
        this.newUser.language_id = 1;
      } else if (this.$i18n.locale === "pt_br") {
        this.newUser.language_id = 2;
      }

      this.newUser.plan_id = this.selectedPlanId;
      this.newUser.hash = this.$route.params.token;
      this.newUser.is_external = this.isExternal;
      this.newUser.profile_id = this.profileId;
      this.newUser.account_id = this.originalAccountId;
      this.newUser.referral = window.localStorage.getItem('ref') ? window.localStorage.getItem('ref') : null;
      this.newUser.token = this.recaptchaToken;
      this.emailSent = this.newUser.email;
      console.log(this.$i18n.locale, this.newUser);

      window.$axios
        .post("api/user/create", this.newUser)
        .then((response) => {
          this.posts = response.data;
          this.isLoading = false;

          this.clearForm();
          this.$helpers.scrollTop(0);
          this.capterraCall();
          this.accountCreated = true;
          this.isSubmitted = true;
        })
        .catch((data) => {
          this.isLoading = false;
          if (data.response && data.response.data) {
            this.formErrors = data.response.data;
          }
          // this.$notify({
          //   message: this.$t("error.generic", {code: 115}),
          //   type: "danger"
          // });
        });
    },
    capterraCall() {
      (function () {
        console.log("Capterra call");
        var capterra_vkey = "7461743738c98fdf7530e688caaa2c2b",
          capterra_vid = "2146680",
          capterra_prefix =
            "https:" == document.location.protocol
              ? "https://ct.capterra.com"
              : "http://ct.capterra.com";
        var ct = document.createElement("script");
        ct.type = "text/javascript";
        ct.async = true;
        ct.src =
          capterra_prefix +
          "/capterra_tracker.js?vid=" +
          capterra_vid +
          "&vkey=" +
          capterra_vkey;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(ct, s);
      })();
    },
    clearForm() {
      this.formErrors = {};
      this.isLoading = false;
      this.newUser.name = "";
      this.newUser.company_name = "";
      this.newUser.language_id = 1;
      this.newUser.email = "";
      this.newUser.password = "";
      this.newUser.password_confirmation = "";
    },
  },
};
</script>

<style scoped>
.check-icon {
  font-size: 3rem;
}
</style>
